<template>
  <div class="base-data">
    <!-- <baseTitle name="系统设置" /> -->
    <el-tabs tab-position="left" :style="{ height: height }" @tab-click="handleClick" v-model="currentType">
      <el-tab-pane label="事项一级分类" name="order">
        <el-button class="iconfont icon-dctianjia" @click="add('order')">添加类别</el-button>
        <ul class="type-list">
          <li v-for="item in orderTypeList" :key="item.id" @mouseover="hover(item.id)" @mouseout="isHover = false">
            <div><i class="iconfont icon-chenggong"></i>{{ item.orderClassficationName }}</div>
            <div class="type-handle" v-show="isHover && currentId === item.id">
              <span class="iconfont icon-bianji" @click="add('order', item.orderClassficationName, item)"></span>
              <span class="iconfont icon-shanchu" @click="del(item.id)"></span>
            </div>
            <img src="@/assets/img/def.png" v-if="item.isDefault == 1" />
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="事项二级分类" name="problem">
        <el-button class="iconfont icon-dctianjia" @click="add('problem')">添加分类</el-button>
        <ul class="type-list">
          <li v-for="item in problemTypeList" :key="item.id" @mouseover="hover(item.id)" @mouseout="isHover = false">
            <div><i class="iconfont icon-chenggong"></i>{{ item.matterClassficationName }}</div>
            <div class="type-handle" v-show="isHover && currentId === item.id">
              <span class="iconfont icon-bianji" @click="add('problem', item.matterClassficationName, item)"></span>
              <span class="iconfont icon-shanchu" @click="del(item.id)"></span>
            </div>
            <img src="@/assets/img/def.png" v-if="item.isDefault == 1" />
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="紧急性" name="nature">
        <el-button class="iconfont icon-dctianjia" @click="add('nature')">添加紧急性</el-button>
        <ul class="type-list">
          <li v-for="item in natureTypeList" :key="item.id" @mouseover="hover(item.id)" @mouseout="isHover = false">
            <div><i class="iconfont icon-chenggong"></i>{{ item.matterPropertyName }}</div>
            <div class="type-handle" v-show="isHover && currentId === item.id">
              <span class="iconfont icon-bianji" @click="add('nature', item.matterPropertyName, item)"></span>
              <span class="iconfont icon-shanchu" @click="del(item.id)"></span>
            </div>
            <img src="@/assets/img/def.png" v-if="item.isDefault == 1" />
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="企业部门" name="department">
        <el-button class="iconfont icon-dctianjia" @click="add('department')">添加部门</el-button>
        <ul class="type-list">
          <li v-for="item in departmentList" :key="item.id" @mouseover="hover(item.id)" @mouseout="isHover = false">
            <div><i class="iconfont icon-chenggong"></i>{{ item.orderDeptName }}</div>
            <div class="type-handle" v-show="isHover && currentId === item.id">
              <span class="iconfont icon-bianji" @click="add('department', item.orderDeptName, item)"></span>
              <span class="iconfont icon-shanchu" @click="del(item.id)"></span>
            </div>
            <img src="@/assets/img/def.png" v-if="item.isDefault == 1" />
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="发生区域" name="area">
        <el-button class="iconfont icon-dctianjia" @click="add('area')">添加区域</el-button>
        <ul class="type-list">
          <li v-for="item in areaList" :key="item.id" @mouseover="hover(item.id)" @mouseout="isHover = false">
            <div><i class="iconfont icon-chenggong"></i>{{ item.areaLocationName }}</div>
            <div class="type-handle" v-show="isHover && currentId === item.id">
              <span class="iconfont icon-bianji" @click="add('area', item.areaLocationName, item)"></span>
              <span class="iconfont icon-shanchu" @click="del(item.id)"></span>
            </div>
            <img src="@/assets/img/def.png" v-if="item.isDefault == 1" />
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
    <baseDialog :title="formTitle" formName="dialogVisible" :dialogVisible="dialogVisible" visibleName="dialogVisible" @cancel="cancel($event)" @submit="submitForm($event)">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="数据名称" :label-width="formLabelWidth" prop="orderClassficationName">
          <el-input v-model="form.orderClassficationName" autocomplete="off" :maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="默认值" :label-width="formLabelWidth">
          <el-switch v-model="form.isDefault"></el-switch>
        </el-form-item>
      </el-form>
    </baseDialog>
    <msgDialog title="删除" tipTxt="确定删除该数据 ？删除后，历史数据继续保留" :baseDialogVisible="baseDialogVisible" @cancel="baseDialogVisible = false" @submitSure="delSure" />
  </div>
</template>

<script>
import baseDialog from '@/components/dialog/base-dialog';
import msgDialog from '@/components/user-dialog/msg-dialog';
// import baseTitle from '@/components/title/base-title';
export default {
  components: {
    msgDialog,
    baseDialog
  },
  data() {
    return {
      height: '',
      formTitle: '添加类别',
      dialogVisible: false,
      baseDialogVisible: false,
      formLabelWidth: ' 80px',
      isHover: false,
      form: {
        id: '',
        orderClassficationName: '',
        isDefault: 1,
        companyCode: 'houhou'
      },
      rules: {
        orderClassficationName: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      currentType: 'order',
      typelist: {
        order: '添加类别',
        problem: '添加分类',
        nature: '添加性质',
        area: '添加区域',
        department: '添加部门'
      },
      orderTypeList: [],
      problemTypeList: [],
      natureTypeList: [],
      areaList: [],
      departmentList: [],
      auto: 'auto'
    };
  },
  mounted() {
    this.getOrderList();
    this.height = `${document.querySelector('main').offsetHeight - 90}px`;
  },
  computed: {
    isDefault: {
      get() {
        if (this.form.isDefault === true) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  },
  methods: {
    add(type, classficationName, item) {
      this.currentType = type;
      if (item) {
        this.form = {
          orderClassficationName: classficationName,
          isDefault: item.isDefault === 1 ? true : false,
          id: item.id,
          companyCode: 'houhou'
        };
      } else {
        this.form = {
          orderClassficationName: '',
          isDefault: false,
          id: '',
          companyCode: 'houhou'
        };
      }
      this.formTitle = this.typelist[type];
      this.dialogVisible = true;
    },
    handleClick({ name }) {
      switch (name) {
        case 'order':
          if (this.orderTypeList.length === 0) {
            this.getOrderList();
          }
          break;
        case 'problem':
          if (this.problemTypeList.length === 0) {
            this.getProblemList();
          }
          break;
        case 'nature':
          if (this.natureTypeList.length === 0) {
            this.getNatureList();
          }
          break;
        case 'area':
          if (this.areaList.length === 0) {
            this.getAreList();
          }
          break;
        case 'department':
          if (this.departmentList.length === 0) {
            this.getDepartmentList();
          }
          break;
        default:
          break;
      }
    },
    //获取事项列表
    async getOrderList() {
      const { data } = await this.$apis.setOrder.list();
      this.orderTypeList = data;
    },
    //获取部门列表
    async getDepartmentList() {
      const { data } = await this.$apis.setDepartment.list();
      this.departmentList = data;
    },
    //获取问题性质列表
    async getNatureList() {
      const { data } = await this.$apis.setNature.list();
      this.natureTypeList = data;
    },
    //获取问题分类列表
    async getProblemList() {
      const { data } = await this.$apis.setProblem.list();
      this.problemTypeList = data;
    },
    //获取区域范围列表
    async getAreList() {
      const { data } = await this.$apis.setArea.list();
      this.areaList = data;
    },
    cancel(formVisible) {
      this[formVisible] = false;
    },
    //编辑修改
    async update(type) {
      let params = {};
      switch (this.currentType) {
        case 'order':
          params = {
            orderClassficationName: this.form.orderClassficationName,
            isDefault: this.isDefault,
            id: this.form.id,
            companyCode: 'houhou'
          };
          break;
        case 'problem':
          params = {
            matterClassficationName: this.form.orderClassficationName,
            isDefault: this.isDefault,
            id: this.form.id,
            companyCode: 'houhou'
          };
          break;
        case 'nature':
          params = {
            matterPropertyName: this.form.orderClassficationName,
            isDefault: this.isDefault,
            id: this.form.id,
            companyCode: 'houhou'
          };
          break;
        case 'area':
          params = {
            areaLocationName: this.form.orderClassficationName,
            isDefault: this.isDefault,
            id: this.form.id,
            companyCode: 'houhou'
          };
          break;
        case 'department':
          params = {
            orderDeptName: this.form.orderClassficationName,
            isDefault: this.isDefault,
            id: this.form.id,
            companyCode: 'houhou'
          };
          break;
        default:
          break;
      }
      const { resp_code } = await this.$apis[type].update(params);
      this.getData(resp_code);
    },
    async getData(resp_code) {
      if (resp_code === 0) {
        switch (this.currentType) {
          case 'order':
            this.getOrderList();
            break;
          case 'problem':
            this.getProblemList();
            break;
          case 'nature':
            this.getNatureList();
            break;
          case 'area':
            this.getAreList();
            break;
          case 'department':
            this.getDepartmentList();
            break;
          default:
            break;
        }
      }
    },
    async submitForm(formVisible) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this[formVisible] = false;
          switch (this.currentType) {
            case 'order':
              this.update('setOrder');
              break;
            case 'problem':
              this.update('setProblem');
              break;
            case 'nature':
              this.update('setNature');
              break;
            case 'area':
              this.update('setArea');
              break;
            case 'department':
              this.update('setDepartment');
              break;
            default:
              break;
          }
          this.form = {
            id: '',
            orderClassficationName: '',
            isDefault: 1,
            companyCode: 'houhou'
          };
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    hover(id) {
      this.currentId = id;
      this.isHover = true;
    },
    del(id) {
      this.currentId = id;
      this.baseDialogVisible = true;
    },
    delSure() {
      switch (this.currentType) {
        case 'order':
          this.delFn('setOrder');
          break;
        case 'problem':
          this.delFn('setProblem');
          break;
        case 'nature':
          this.delFn('setNature');
          break;
        case 'area':
          this.delFn('setArea');
          break;
        case 'department':
          this.delFn('setDepartment');
          break;
        default:
          break;
      }
    },
    //删除
    async delFn(type) {
      const { resp_code, resp_msg } = await this.$apis[type].del(this.currentId);
      if (resp_code === 0) {
        this.$message({
          message: resp_msg
        });
        this.getData(resp_code);
        this.baseDialogVisible = false;
      } else {
        this.$message({
          message: resp_msg
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-data {
  height: 90vh;
}
.type-list {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  li {
    width: 410px;
    height: 110px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #dcdfe6;
    margin-right: 10px;
    cursor: pointer;
    // align-items: center;
    // display: flex;
    // justify-content: center;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
    color: #121212;
    font-size: 14px;
    img {
      width: 30px;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      box-shadow: 0px 2px 10px 0px rgba(18, 18, 18, 0.16);
      .icon-chenggong {
        display: inline-block;
      }
    }
    .icon-chenggong {
      margin-right: 10px;
      color: #f39800;
      display: none;
    }
    .type-handle {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 70px;
      left: 320px;
      span {
        // margin-right: 20px;
        margin: 0 10px;
      }
      .iconfont {
        font-size: 18px;
      }
    }
  }
}
</style>
