<template>
  <el-dialog :title="title" :visible.sync="visible" width="500px" @close="cancel" :close-on-click-modal="false">
    <slot></slot>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit" :disabled="isSend">{{ btnTxt }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: String,
    formName: String,
    visibleName: String,
    btnTxt: {
      type: String,
      default: '确定'
    },
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isSend: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.dialogVisible;
      },
      set() {
        this.$emit('cancel', this.visibleName);
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel', this.visibleName);
    },
    submit() {
      this.$emit('submit', this.formName);
    }
  }
};
</script>
