<template>
  <baseDialog :title="title" :dialogVisible="baseDialogVisible" visibleName="baseDialogVisible" @cancel="cancel($event)" @submit="submitSure($event)">
    <div class="tips-content"><span class="warn iconfont icon-jinggao"></span>{{ tipTxt }}</div>
  </baseDialog>
</template>
<script>
import baseDialog from '@/components/dialog/base-dialog';
export default {
  components: {
    baseDialog
  },
  name: 'msgDialog',
  props: {
    title: String,
    baseDialogVisible: {
      type: Boolean,
      default: false
    },
    tipTxt: String
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submitSure() {
      this.$emit('submitSure');
    }
  }
};
</script>
<style lang="scss" scoped>
.tips-content {
  display: flex;
  align-items: center;
}
.warn {
  font-size: 42px;
  color: #f39800;
  margin-right: 10px;
}
</style>